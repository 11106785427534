import React, { useState, useEffect } from "react";
import { Transition } from '@headlessui/react'
import { checkUser } from '../services/magic';
import { navigate, useStaticQuery, graphql } from "gatsby";
import { LoginDialog } from "./LoginDialog";
import { Link } from "gatsby";

export const useUser = (redirect = null) => {
  const [user, setUser] = useState({ isLoggedIn: null, email: '', loading: true });
  useEffect(() => {
    const validateUser = async () => {
      try {
        await checkUser(setUser);
      } catch (error) {
        console.error(error);
      }
    };
    validateUser();
  }, [user.isLoggedIn]);

  useEffect(() => {
    if (!user.isLoggedIn && !user.loading && redirect) {
      console.log(`not logged in`);
      navigate(redirect);
    }
  });
  return user;
};

export default function Layout({ children, selected = "" }) {
  const [loginOpen, setLoginOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      contentfulBranding {
        icon {
          file {
            url
          }
          title
        }
        slogan
      }
      allContentfulDienstPagina {
        edges {
          node {
            slug
            title
            subtitle
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
  const icon = data.contentfulBranding.icon.file.url;
  const [dienstenOpen, setDienstenOpen] = useState(false);
  return (
    <>
      {loginOpen && <LoginDialog />}
      {/* This example requires Tailwind CSS v2.0+ */}
      <div className="relative bg-white">
        <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Itadise</span>
              <img className="h-8 w-auto sm:h-10" src={icon} alt="" />
            </Link>
            <Link to="/">
              <div className="h-8 sm:h-10 ml-2 text-3xl text-indigo-900 logo-text">itadise</div>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            {/* FIXME menu doesn't open on mobile yet */}
            <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
              <span className="sr-only">Open menu</span>
              {/* Heroicon name: outline/menu */}
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <nav className="hidden md:flex space-x-10">
            <div className="relative">
              <button onClick={() => setDienstenOpen(!dienstenOpen)} className={`text-base group font-medium inline-flex items-center text-base text-gray-500 hover:text-gray-900`}>
                <span>Diensten</span>
                <svg className="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              <Transition
                show={dienstenOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="absolute z-10 -ml-4 mt-2 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      {data.allContentfulDienstPagina.edges.map(obj => (
                        <div key={obj.node.slug}>
                          <Link to={`/diensten/${obj.node.slug}`} className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                            {/* Heroicon name: outline/chart-bar */}
                            <img className="flex-shrink-0 h-6 w-6 text-indigo-600" src={obj.node.icon.file.url} />
                            <div className="ml-4">
                              <p className="text-base font-medium text-gray-900">
                                {obj.node.title}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {obj.node.subtitle}
                              </p>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Transition>
            </div>

            <Link to="/dienstverleners" className={`text-base font-medium text-gray-${selected === "dienstverleners" ? 800 : 500} hover:text-gray-900`}>
              Dienstverleners
            </Link>
            <Link to="/insights" className={`text-base font-medium text-gray-${selected === "insights" ? 800 : 500} hover:text-gray-900`}>
              Insights
            </Link>
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {/* onClick={() => setLoginOpen(true)} */}
            <Link to="/comingsoon" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              Login
            </Link>
          </div>
        </div>
      </div>
      {children}
      <footer className="landing-footer" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">Footer</h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <div className="flex">
                <img className="h-10" src={icon} alt="Company name" />
                <div className="h-8 sm:h-10 ml-2 text-3xl text-white logo-text">itadise</div>
              </div>
              <p className="text-white text-base">
                {data.contentfulBranding.slogan}
              </p>
            </div>
            <div className="mt-12 grid grid-cols-1 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-3 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                    Dienstverleners
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <Link to="/comingsoon" className="text-base text-gray-300 hover:text-gray-500">
                        Aanmelden
                      </Link>
                    </li>

                    <li>
                      <Link to="/pricing" className="text-base text-gray-300 hover:text-gray-500">
                        Tarieven
                      </Link>
                    </li>

                    <li>
                      <Link to="/comingsoon" className="text-base text-gray-300 hover:text-gray-500">
                        FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                    Over ons
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <Link to="/about" className="text-base text-gray-300 hover:text-gray-500">
                        Team
                      </Link>
                    </li>

                    <li>
                      <a href="/comingsoon" className="text-base text-gray-300 hover:text-gray-500">
                        LinkedIn
                      </a>
                    </li>

                    <li>
                      <Link to="/comingsoon" className="text-base text-gray-300 hover:text-gray-500">
                        Vacatures
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base text-gray-400 xl:text-center">
              <Link to="/comingsoon" className="px-3">
                Privacyverklaring
              </Link>
              <Link to="/comingsoon" className="px-3">
                Cookiebeleid
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  )
};