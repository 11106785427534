import { Magic } from 'magic-sdk';
console.log("process.env", process.env);
const magic = typeof window !== 'undefined' ? new Magic(`pk_test_595E8B48DE4D8872`) : null;

export const checkUser = async (cb) => {
  const isLoggedIn = magic && await magic.user.isLoggedIn();
  if (isLoggedIn) {
    const user = magic && await magic.user.getMetadata();
    return cb({ isLoggedIn: true, email: user?.email });
  }
  return cb({ isLoggedIn: false });
};

export const loginUser = async (email) => {
  if (magic) {
    const didToken = await magic.auth.loginWithMagicLink({
      email
    });
    console.log(`didToken received`, didToken);
    localStorage.setItem("didToken", didToken);
  }
};

export const logoutUser = async () => {
  await magic && magic.user.logout();
};