import React, { useState } from 'react';
import { navigate } from "gatsby";
import { loginUser, logoutUser } from '../services/magic';
import { TextField } from './TextField';
import { Modal } from './Modal';
import { Spinner } from "./Spinner";
import { PrimaryButton } from './Buttons';
import { useUser } from "./Layout";

export const LoginButton = () => {
  const [loginOpen, setLoginOpen] = useState(false);
  const user = useUser("/");
  return (
    <div className="flex">
      <div
        className="flex items-center justify-center border-2 mr-2 h-10 border-black rounded-full px-5"
        onClick={() => setLoginOpen(true)}
      >
        {user.isLoggedIn ? "Logout" : "Login"}
      </div>
      {loginOpen && (user.isLoggedIn ? <LogoutDialog /> : <LoginDialog />)}
      <div className="flex items-center justify-center bg-yellow-500 h-10 text-white rounded-full px-5">Start selling</div>
    </div>
  )
};

export const LoginDialog = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState('');
  const [error, setError] = useState(null);

  // const history = useHistory();
  const handleSubmit = async (event) => {
    console.log(`email`, email);
    event.preventDefault();
    setLoading(true);
    if (!email) {
      setLoading(false);
      setError('Email is Invalid');
      return;
    }
    try {
      await loginUser(email);
      setLoading(false);
      console.log(`success`);
      navigate('/dashboard');
    } catch (error) {
      setError('Unable to log in');
      console.error(error);
    }
  };
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <Modal
      title="Login"
      content={<TextField onChange={handleChange} value={email} label="Enter your e-mail address" />}
      actions={<>
        {error}
        <PrimaryButton onClick={handleSubmit}>
          {loading && <Spinner />}
          Login
        </PrimaryButton>
      </>}
    />
  )
};


export const LogoutDialog = () => {
  return (
    <Modal
      title="Logout"
      content={null}
      actions={<>
        <PrimaryButton onClick={() => logoutUser().then(() => navigate('/'))}>
          Logout
        </PrimaryButton>
      </>}
    />
  )
}